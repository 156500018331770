.wrapper {
  position: relative;
  user-select: none;
}

.button {
  display: flex;
  border: 1px solid var(--colors-primary-grey50);
  border-radius: 2px;
  background: none;
  padding: 7px 8px;
  cursor: pointer;
  align-items: center;
  color: var(--colors-primary-navy100);
  letter-spacing: 0.25px;
  width: 100%;
}

.button:hover {
  border-color: var(--colors-primary-blue60);
  color: var(--colors-primary-blue60);
}

.button:active {
  border-color: var(--colors-primary-navy100);
  color: var(--colors-primary-navy100);
}

.button span {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 4px;
}

.options {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  right: 0;
  min-width: 100%;
  z-index: 2;
  background-color: var(--colors-primary-white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.option {
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.option:hover {
  background-color: var(--colors-primary-grey20);
}

.option.selected {
  color: var(--colors-primary-navy100);
  cursor: default;
}

.option.disabled {
  color: var(--colors-primary-grey70);
  cursor: not-allowed;
}

.variantDropdown {
  width: 100%;
  margin-bottom: 16px;
}

.icon {
  margin-left: auto;
}
