.container {
  margin-left: 16px;
  display: flex;
}

@media (max-width: 768px) {
  .container.desktop {
    display: none;
  }
}

.button {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  border: 1px solid var(--colors-primary-grey50);
  color: var(--colors-primary-navy100);
  background: none;
  border-radius: 2px;
  height: 40px;
}

.button:hover {
  border-color: var(--colors-primary-blue60);
  color: var(--colors-primary-blue60);
}

.button:last-child {
  margin-left: 8px;
}

.button.active {
  border-color: var(--colors-primary-navy100);
  color: var(--colors-primary-navy100);
}
