.container {
  box-sizing: border-box;
  position: relative;
}
.container *,
.container *:before,
.container *:after {
  box-sizing: inherit;
}
@media (min-width: 600px) {
  .container {
    height: 400px;
  }
}

.imageContainer .image {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 600px) {
  .imageContainer .image {
    display: block;
  }
}

.emptyContainer {
  padding: 32px 16px 0 16px;
  margin-bottom: 32px;
}
.titlecard {
  width: 100%;
  padding: 16px;
  top: 50%;
  left: 16px;
}
.emptyContainer,
.titlecard {
  background: var(--colors-primary-white);
  color: var(--colors-primary-grey100);
}

@media (min-width: 600px) {
  .titlecard {
    width: 520px;
    max-width: calc(100% - 32px);
    position: absolute;
    left: 32px;
    padding: 32px;
    transform: translate(0, -50%);
  }

  .emptyContainer {
    padding: 32px 32px 0 32px;
  }
}

@media (min-width: 769px) {
  .titlecard {
    left: 64px;
  }

  .emptyContainer {
    padding: 32px 64px 0 0;
  }
}

.rectangle {
  height: 2px;
  width: 64px;
  margin: 8px 0;
  background: var(--colors-primary-gold60);
}

@media (min-width: 600px) {
  .titlecard .rectangle {
    margin: 16px 0;
  }
}

.visibleDesktopHiddenMobile {
  display: none;
}

@media (min-width: 600px) {
  .visibleMobileHiddenDesktop {
    display: none !important;
  }

  .visibleDesktopHiddenMobile {
    display: initial !important;
  }
}

@media (max-width: 1024px) {
  .emptyContainer {
    padding-left: 64px;
  }
}

@media (max-width: 800px) {
  .emptyContainer {
    padding-left: 64px;
  }
}

@media (max-width: 768px) {
  .emptyContainer {
    padding-left: 32px;
  }
}

@media (max-width: 694px) {
  .emptyContainer {
    padding-left: 32px;
  }
}

@media (max-width: 568px) {
  .emptyContainer {
    padding-left: 16px;
  }
}
