.container {
  position: relative;
  text-align: center;
  color: var(--colors-primary-white);
  user-select: none;
}

.imageContainer {
  text-align: center;
  color: var(--colors-primary-white);
  user-select: none;
}

.imageContainer div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay-wb, serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
}

.visibleDesktopHiddenMobile {
  display: none;
}

@media (min-width: 769px) {
  .title {
    font-size: 64px;
    line-height: 72px;
  }
}

@media (min-width: 1025px) {
  .visibleMobileHiddenDesktop {
    display: none;
  }

  .visibleDesktopHiddenMobile {
    display: initial;
  }
}
