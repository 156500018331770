.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  color: var(--colors-primary-grey100);
  cursor: pointer;
}

.title {
  font-family: benton-sans, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.button {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  margin-right: 1px;
}

.button:focus {
  outline: 2px auto var(--colors-alert-turq60);
}

.chevron {
  color: var(--colors-primary-navy100);
}
