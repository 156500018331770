/* Title */
.h0,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay-wb, serif;
  font-weight: 400;
}
.h0 a:not([class]),
.h1 a:not([class]),
.h2 a:not([class]),
.h3 a:not([class]),
.h4 a:not([class]),
.h5 a:not([class]) {
  text-decoration: none;
}

.h0 {
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 64px;
}
.h1 {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 64px;
}
.h2 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;
}
.h3 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 32px;
}
.h4 {
  font-size: 24px;
  line-height: 28px;
}
.h5 {
  font-size: 20px;
  line-height: 24px;
}

/* Shared text styles */
.paragraph,
.label,
.caption {
  font-weight: 500;
  font-family: benton-sans, sans-serif;
}

.book {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

/* Paragraph */
.paragraph {
  font-size: 18px;
  line-height: 32px;
}
.paragraph.small {
  font-size: 16px;
  line-height: 24px;
}

.paragraph.extraSmall {
  font-size: 14px;
  line-height: 22px;
}

/* Label */
.label {
  font-size: 14px;
  line-height: 22px;
}
.label.small {
  font-size: 12px;
  line-height: 16px;
}

.label.medium {
  font-size: 16px;
  line-height: 24px;
}

.label.semibold {
  font-weight: 600;
}

/* Caption */
.caption {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}
.caption.small {
  font-size: 12px;
  line-height: 16px;
}
.caption.xsmall {
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 12px;
}

/* Price */
.price {
  font-family: "benton-sans";
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.price.small {
  line-height: 16px;
  font-size: 14px;
}
