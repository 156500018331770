.innerHtml {
  color: var(--colors-primary-gray-100);
  font-family: "benton-sans";
  display: block;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.innerHtml ul {
  list-style: disc;
  list-style-position: inside;
}

.innerHtml strong {
  font-weight: bold;
}

.innerHtml a {
  color: var(--colors-primary-blue60);
}

.innerHtml a:visited {
  color: var(--colors-primary-blue60);
}

.innerHtml em {
  font-style: italic;
}
