.container {
  width: 100%;
}

.ariaMessage {
  visibility: hidden;
  height: 0px;
}

.container div:last-child {
  border: 0;
}

.clearAllWrapper {
  display: none;
}

@media (min-width: 769px) {
  .clearAllWrapper {
    display: block;
  }
}
