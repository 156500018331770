.wrapper {
  position: relative;
}

.extraWrapper {
  max-width: 1312px;
}

/* hide scrollbar but allow scrolling */
.slideWrapper {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.slideWrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* hide scrollbar but allow scrolling */
.slideWrapper {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.slideWrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.slideWrapper {
  box-sizing: border-box;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  padding: 0;
  overflow: scroll hidden;

  /* hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer, Edge */
  scrollbar-width: none; /* Firefox */
}
.slideWrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.slideWrapper *,
.slideWrapper *:before,
.slideWrapper *:after {
  box-sizing: inherit;
}

.leftGradient,
.rightGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 200;
  opacity: 1;
}
.rightGradient {
  background: linear-gradient(270deg, white, rgba(255, 255, 255, 0) 32px);
}
.leftGradient {
  background: linear-gradient(90deg, white, rgba(255, 255, 255, 0) 32px);
}

.slide {
  display: inline-flex;
  z-index: 100;
}

.frame {
  display: flex;
  flex-direction: column;
  width: 128px;
  margin: 0 24px 0 0;
  text-decoration: none;
  color: var(--colors-primary-black);
}
.frame:last-child {
  margin: 0;
}
.frame .image {
  width: 100%;
  height: 72px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 2px;
}
.frame .title {
  height: 40px;
  user-select: none;
  color: var(--colors-primary-black);
  text-align: center;
}
.frame .title p {
  margin: 8px 0;
}
.frame a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 599px) {
  .slideWrapper {
    height: 158px;
  }
  .slide {
    padding-left: 16px;
    padding-right: 16px;
  }

  .frame .image {
    height: 128px;
  }
  .frame {
    margin: 0 16px 0 0;
  }

  .rightGradient {
    background: linear-gradient(270deg, white, rgba(255, 255, 255, 0) 16px);
  }
  .leftGradient {
    background: linear-gradient(90deg, white, rgba(255, 255, 255, 0) 16px);
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .slideWrapper {
    height: 228px;
  }

  .slide {
    padding-left: 32px;
    padding-right: 32px;
  }

  .frame {
    display: flex;
    flex-direction: column;
    width: 199px;
    margin: 0 24px 0 0;
  }

  .frame .image {
    height: 199px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .slide {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (min-width: 1025px) {
  .slideWrapper {
    height: auto;
  }

  .slide {
    width: 100%;
  }

  .leftGradient,
  .rightGradient {
    opacity: 0;
  }

  .frame {
    /* Default width */
    width: calc(100% / 6 - 20px);
  }
  .slide-5 .frame {
    width: calc(100% / 5 - 19.2px);
  }
  .slide-4 .frame {
    width: calc(100% / 4 - 18px);
  }
  .slide-1 .frame,
  .slide-2 .frame,
  .slide-3 .frame {
    width: calc(100% / 3 - 16px);
  }

  .frame .image {
    height: auto;
    /* to change aspect ration change the padding height
       For example: calc(0.5625 * 100%) is 16:9 */
    padding-top: 100%;
  }
  .slide-1 .frame .image,
  .slide-2 .frame .image {
    height: 184px;
    padding-top: 0;
  }
}
