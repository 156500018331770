@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1150;
  background-color: var(--colors-primary-white);
  overflow: hidden;
  opacity: 0;
  animation: fadeOut 0.25s;
  transition: opacity 0.25s ease-in-out;
}

.modalWrapper.scrollable {
  overflow-y: auto;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

.modalBody {
  width: 502px;
  max-width: 75%;
  margin: 100px auto 0;
  background: var(--colors-primary-white);
  padding: 40px 24px;
  position: relative;
}

.modalBody .large {
  max-width: 724px;
}

.modalBody h4 {
  margin-bottom: 28px;
  line-height: 32px;
}

.modalWrapper.visible {
  opacity: 1;
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 44px;
  padding: 8px;
  border: 0;
  background: none;
}

.closeBtn:focus {
  outline: none;
}

@media (min-width: 600px) {
  .modalBody {
    padding: 40px 36px;
  }
}

@media (min-width: 1025px) {
  .modalWrapper .modalBody > span {
    font-size: 18px;
    line-height: 26px;
  }
}
