.sortByWrapper {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid var(--colors-primary-grey30);
  border-bottom: 1px solid var(--colors-primary-grey30);
}

.clearAllWrapper div {
  margin-top: 0;
  margin-bottom: 25px;
}

.accordionHeader {
  font-family: benton-sans, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.radioWrapper {
  padding-top: 22px;
}

.dropdownWrapper {
  display: none;
}

@media (min-width: 769px) {
  .sortByWrapper {
    display: none;
  }

  .dropdownWrapper {
    display: block;
  }

  .radioWrapper {
    display: none;
  }
}
