.container {
  display: flex;
  padding: 24px;
  border: 1px solid var(--colors-primary-grey20);
  border-radius: 3px;
}

.stockMobile {
  display: none;
}

.container:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.informationBlocks {
  display: flex;
  justify-content: space-between;
}

.mainInfoBlock {
  width: 100%;
}

.image {
  min-width: 144px;
  height: 144px;
  text-align: center;
}

.image img {
  max-width: 144px;
  max-height: 144px;
}

.productInfo {
  padding: 0 8px;
}

.producedIn {
  margin-bottom: 4px;
  color: var(--colors-primary-grey90);
}

.priceInfo {
  margin-top: 8px;
  color: var(--colors-primary-grey80);
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}

.stock,
.stockMobile,
.size {
  color: var(--colors-primary-grey80);
}

.outOfStock {
  color: var(--colors-alert-red60);
}

.priceContainer {
  margin-left: auto;
  text-align: right;
  padding-top: 4px;
}

.activeElements {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quantitySelect {
  max-width: 108px;
  margin-left: 8px;
  margin-right: 16px;
}

.buyButton {
  max-width: 160px;
}

.actionLabel {
  padding-left: 8px;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 600;
  color: var(--colors-primary-blue60);
}

.actionLabelAndCartContainer {
  display: none;
  align-items: flex-end;
}

.labelAfterIcon {
  padding-left: 4px;
}

@media (max-width: 768px) {
  .image {
    min-width: 72px;
    height: 72px;
  }

  .image img {
    max-width: 72px;
    max-height: 72px;
  }
}

@media (max-width: 600px) {
  .informationBlocks {
    display: block;
    justify-content: unset;
  }

  .productInfo {
    width: 100%;
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .stock {
    display: none;
  }

  .stockMobile {
    display: block;
  }

  .priceContainer {
    text-align: left;
    padding-left: 8px;
  }

  .activeElements {
    display: none;
  }

  .actionLabel {
    display: flex;
  }

  .actionLabelAndCartContainer {
    display: flex;
  }

  .producedIn {
    margin-bottom: 8px;
  }
}

.quantitySelect .selectList {
  max-height: 150px;
  overflow-y: auto;
}

.certifiedPreOwnedLabel {
  margin-top: 8px;
  color: var(--colors-primary-grey80);
  text-transform: uppercase;
}
