@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

.cardContainer {
  margin-bottom: 32px;
}

.listContainer {
  margin-bottom: 16px;
}

.linkStyles {
  color: inherit;
  text-decoration: none;
}

.noResultsWrapper {
  text-align: center;
  max-width: 667px;
  margin: 64px auto;
}

.hierarchyContainer {
  margin-top: 16px;
  margin-bottom: 16px;
}

.heroImage {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

.hiddenSmallVisibleLarge {
  display: none;
}

.featuredLinksWrapper {
  margin-top: 32px;
}

.extraWrapper {
  max-width: 1440px;
}

@media (max-width: 1024px) {
  /* On mobile width 100% */
  .featuredLinksWrapper {
    margin: 32px 0 0 0;
  }
}

.toolbarWrapper {
  margin-top: 16px;
}

.pageTitleContainer {
  margin-top: 34px;
}

.container .sidebar {
  padding: 32px 16px;
  margin-left: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--colors-primary-white);
  z-index: 1130;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 500ms;
}

.container.visibleDesktop .sidebar {
  display: block;
  transform: translateX(-100%);
  box-shadow: unset;
}

.container.hiddenMobileDesktop .sidebar {
  display: none;
}

.container.visibleMobileDesktop .sidebar {
  display: block;
  transform: translateX(0);
}

.hitsContainer {
  outline: none;
}

.sideBarBackdrop {
  background: var(--colors-primary-black);
  z-index: 1125;
  opacity: 0.2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: fadeIn 1.25s;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
}

.rightToolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}

.vatLink {
  text-decoration: none;
}

.categoryInfo {
  width: 100%;
}

.statsWrapper {
  color: var(--colors-primary-grey80);
  font-size: 14px;
  line-height: 22px;
  flex-shrink: 0;
}

.sorter {
  display: flex;
  flex-wrap: nowrap;
}

.filtersTitle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
}

.filtersTitle h3 {
  margin-bottom: 0;
}

.clearAllWrapper {
  position: relative;
  bottom: 4px;
}

.closeWrapper {
  padding: 4px;
}

.searchBoxMobile {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.searchBoxMobile form {
  width: 100%;
  height: 40px;
}

.searchBoxWrapper {
  display: none;
}

.currencyButtonMobile {
  margin-right: 12px;
}

.currencyButtonDesktop {
  margin-right: 16px;
}

@media (max-width: 768px) {
  .currencyButtonDesktop {
    display: none;
  }

  .vatInfoDesktop {
    display: none;
  }
}

.filtersBtn {
  border: 1px solid var(--colors-primary-grey50);
  color: var(--colors-primary-navy100);
  border-radius: 2px;
  background: none;
  padding: 4px 8px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
  user-select: none;
  width: 135px;
  white-space: nowrap;
}

.searchBoxMobile .filtersBtn {
  display: flex;
  margin-right: 0;
  height: 42px;
  width: auto;
}

.searchBoxMobile .searchBoxOtherButtonsMobile {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.searchBoxMobile .searchBoxOtherButtonsMobile .currencyButtonMobile {
  margin-right: auto;
}

.filtersBtn span {
  text-transform: capitalize;
  display: inline-block;
  margin-right: 8px;
}

.filtersBtn:hover {
  border-color: var(--colors-primary-blue60);
  color: var(--colors-primary-blue60);
}

.filtersBtn:active {
  border-color: var(--colors-primary-navy100);
  color: var(--colors-primary-navy100);
}

.collectionTitle {
  font-family: Mercury Display A, Mercury Display B, MercuryDisplay-wb, serif;
  font-size: 32px;
  line-height: 40px;
}

@media (min-width: 601px) {
  .noResultsWrapper {
    margin: 128px auto;
  }
  .hierarchyContainer {
    margin-top: 24px;
  }
  .toolbarWrapper {
    margin-top: 32px;
  }

  .searchBoxMobile .searchBoxOtherButtonsMobile {
    margin-top: 0;
    justify-content: space-between;
  }

  .searchBoxMobile {
    display: flex;
  }

  .searchBoxMobile form,
  .searchBoxMobile .searchBoxOtherButtonsMobile .currencyButtonMobile {
    margin-right: 16px;
  }
}

@media (min-width: 769px) {
  .hierarchyContainer {
    margin-bottom: 32px;
  }

  .categoryInfo {
    width: auto;
  }

  .hiddenSmallVisibleLarge {
    display: inline-block;
  }

  .searchBoxMobile {
    display: none;
  }

  .vatInfoMobile {
    display: none;
  }

  .searchBoxWrapper {
    display: inline;
  }

  .container .sidebar {
    position: relative;
    z-index: auto;
    overflow: hidden;
    box-shadow: none;
    padding: 0;
    margin-left: 12px;
    transition: none;
  }

  .container.visibleDesktop .sidebar {
    display: inline;
    transform: unset;
  }

  .container.hiddenMobileDesktop {
    grid-template-columns: [content] 1fr;
  }

  .container.hiddenMobileDesktop .sidebar {
    display: none;
  }

  .filtersTitle {
    display: none;
  }

  .sideBarBackdrop {
    display: none;
  }

  .statsWrapper {
    margin-bottom: 4px;
  }

  .filtersBtn {
    display: flex;
  }
}

.lockBodyScroll {
  overflow: hidden;
}

.alcoholWarning {
  padding: 8px 16px;
  background: var(--colors-primary-grey10);
  margin-top: 32px;
  margin-bottom: 48px;
}
