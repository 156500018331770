.accordionHeader {
  font-family: benton-sans, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.options {
  max-height: 320px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 24px;
}

.mobileView {
  max-height: inherit;
}

.mobileView .options {
  max-height: inherit;
}

.optionsWrapper {
  position: relative;
}

.optionsGradient::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    var(--colors-primary-white)
  );
  opacity: 0.8;
  left: 0;
  pointer-events: none;
}

.clearAll {
  display: inline-block;
  color: var(--colors-primary-blue60);
  font-size: 16px;
  line-height: 19px;
}

.clearAll.hidden {
  display: none;
}

.filterWrapper {
  padding-bottom: 25px;
  border-bottom: 1px solid var(--colors-primary-grey30);
}

.showMore,
.showMoreMobile {
  line-height: 32px;
  cursor: pointer;
  display: none;
  color: var(--colors-primary-blue60);
}

.showMoreMobile {
  display: inline-block;
}

.searchBox {
  border: 1px solid var(--colors-primary-grey50);
  border-radius: 2px;
  position: relative;
  margin-top: 24px;
}

.ariaSearchMessage {
  visibility: hidden;
  height: 0px;
}

.searchBox:hover {
  background: var(--colors-primary-grey10);
  border: 1px solid var(--colors-primary-grey70);
}

.searchBox:focus-within {
  background: var(--colors-primary-white);
  outline: 2px auto var(--colors-alert-turq60);
  outline-offset: 0;
}

.inputBox {
  border: 0;
  padding: 10px 48px 10px 16px;
  line-height: 20px;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.inputBox:focus {
  outline: none;
}

.inputBox::placeholder {
  color: var(--colors-primary-grey80);
}

.searchLogo {
  line-height: 10px;
  position: absolute;
  right: 10px;
  top: 8px;
  pointer-events: none;
}

.expandedOptionsWrapper {
  padding: 16px;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colors-primary-white);
  z-index: 1130;
}

.goBackLink p {
  padding: 0 0 16px;
  color: var(--colors-primary-blue60);
  cursor: pointer;
}

.goBackLink:focus {
  outline: auto;
}

.optionsContainer {
  /* 160px = searchbox + header + wrapperPadding*/
  max-height: calc(100vh - 248px);
  overflow: auto;
  margin-top: 24px;
}

.optionsContainer .options {
  margin: 0;
}

@media (min-width: 769px) {
  .expandedOptionsWrapper {
    padding: 0;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
  }
  .expandedOptionsWrapper .accordionHeader,
  .expandedOptionsWrapper .searchBox {
    display: none;
  }
  .goBackLink {
    display: none;
  }
  .showMoreMobile {
    display: none;
  }
  .showMore {
    display: inline-block;
    margin-left: 1px;
  }
}
