.filterContainer {
  padding-top: 16px;
  display: flex;
  flex-flow: column;
}

.clearLabel {
  line-height: 32px;
  cursor: pointer;
  color: var(--colors-primary-blue60);
  display: inline-block;
}

.priceFilterActions {
  display: flex;
  justify-content: space-between;
}
