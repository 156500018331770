.label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
  user-select: none;
  margin-left: 2px;
}

.label.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.checkmarkDisabled {
  opacity: 0.2;
  pointer-events: none;
}

.label input:checked ~ .checkmark {
  background-color: var(--colors-primary-navy100);
  border-color: var(--colors-primary-navy100);
}

.label.disabled input ~ .checkmark {
  background-color: var(--colors-primary-navy100);
  border: 0;
}

.label:hover input ~ .checkmark {
  border-color: var(--colors-primary-grey70);
}

.label input:active ~ .checkmark,
.label input:focus ~ .checkmark {
  outline: 2px auto var(--colors-alert-turq60);
  outline-offset: 2px;
}

.label:hover input:checked ~ .checkmark {
  background-color: var(--colors-primary-blue60);
  border-color: var(--colors-primary-blue60);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.label input:checked ~ .checkmark:after {
  display: block;
}

.label .checkmark:after {
  left: 7px;
  top: 3px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--colors-primary-grey40);
  box-sizing: border-box;
  border-radius: 2px;
}
