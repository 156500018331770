.container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 40px;
  z-index: 123123123;
  background-color: rgb(94, 94, 94);
}

.enabled {
  color: green;
  font-weight: 700;
}
.disabled {
  color: red;
}

.feature {
  margin: 15px;
  padding: 10px;
  border: 1px solid black;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(152, 188, 211);
  border-radius: 4px;
}

.feature > div {
  margin-top: 2px;
}

.featureEnabled {
  box-shadow: 0px 0px 5px 5px rgba(0, 255, 0, 0.75);
}

.featureName {
  font-weight: 600;
}

@keyframes color-change {
  0% {
    color: red;
  }
  25% {
    color: purple;
  }
  50% {
    color: blue;
  }
  75% {
    color: yellow;
  }
  100% {
    color: green;
  }
}
.visibleInProd {
  margin-top: 4px;
  animation: color-change 2s infinite alternate;
}
