.container {
  cursor: pointer;
  height: 100%;
}

.imageContainer {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0 0; /* Percentage calc example: 34.37% = 100 / (w / h) = 100 / (640 / 220) */
  margin-bottom: 8px;
}

.imageContainer img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.certifiedPreOwnedLabel {
  margin-top: 8px;
  color: var(--colors-primary-grey80);
  text-transform: uppercase;
}

.creator {
  text-overflow: ellipsis;
  overflow: hidden;
}

.spacer {
  margin-top: 26px;
}

.threeLineClamp {
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
}

.twoLineClamp {
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
}

.priceInfo {
  margin-top: 8px;
  line-height: 22px;
}

.lineThrough {
  text-decoration: line-through;
}

.actionLabel {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 600;
  color: var(--colors-primary-blue60);
}

.actionLabelAndCartContainer {
  display: flex;
  align-items: flex-end;
}

.labelAfterIcon {
  padding-left: 4px;
}
