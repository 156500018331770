.clearAll {
  display: inline-block;
  margin-top: 25px;
  color: var(--colors-primary-blue60);
  cursor: pointer;
  line-height: 22px;
  font-size: 16px;
}

.clearAll.hidden {
  display: none;
}
