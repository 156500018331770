.label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
  user-select: none;
}

.radioInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--colors-primary-grey40);
  box-sizing: border-box;
  border-radius: 50%;
}

.label input:checked ~ .checkmark {
  background-color: var(--colors-primary-navy100);
}

.label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background-color: var(--colors-primary-white);
  border-radius: 50%;
}

.label input:checked ~ .checkmark:after {
  display: block;
}

@media (min-width: 600px) {
  .label:hover input ~ .checkmark {
    background-color: var(--colors-primary-blue60);
  }
}
