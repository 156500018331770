.iconSpace {
  margin-right: 8px;
}

.currencyRows {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.currencyRows li {
  margin-top: 2px;
  margin-bottom: 2px;
}

.currencyRows li:nth-child(odd) {
  background-color: var(--colors-primary-grey10);
}

.currencyButton:hover,
.currencyButton:focus {
  background-color: var(--colors-primary-blue10) !important;
}

.modalDescription {
  margin-bottom: 24px;
  margin-top: 24px;
}

.informationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}

.imageAndCurrency {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flagContainer {
  display: flex;
  margin-right: 8px;
}

.selectedCurrencyButtonContainer {
  display: flex;
  align-items: center;
}

.selectedCurrencyButtonContainer svg {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

@media (max-width: 1025px) {
  .selectedCurrencyButtonPDP {
    max-width: 64px !important;
    min-width: 64px !important;
    max-height: 32px !important;
  }

  .selectedCurrencyButtonContainer svg {
    display: none;
  }
}

@media (max-width: 768px) {
  .selectedCurrencyButtonBrowse {
    max-width: 64px !important;
    min-width: 64px !important;
    max-height: 42px !important;
    min-height: 42px !important;
  }
}
