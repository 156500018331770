.accordion {
  padding: 8px 0;
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 54px;
  color: var(--colors-primary-black);
}

.description {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  line-height: 54px;
  padding-left: 16px;
}

.body {
  padding: 32px 0 8px;
  position: relative;
}
.title {
  font-weight: 700;
}

.accordion .accordion {
  margin-left: 32px;
  margin-right: 32px;
}
.body > .accordion:first-child {
  margin-top: 16px;
}

@media (min-width: 600px) {
  .accordion {
    padding: 16px;
  }
}

@media (min-width: 1025px) {
  .accordion {
    padding: 8px 0;
  }

  .description img {
    display: none;
  }
}
