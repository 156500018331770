.container {
  color: var(--colors-primary-grey80);
  font-size: 14px;
  line-height: 22px;
  font-family: "benton-sans";
  display: inline-block;
}

.link {
  text-transform: capitalize;
  cursor: pointer;
}

.link:hover {
  color: var(--colors-primary-blue60);
}

.activeItem {
  display: inline;
  text-transform: capitalize;
  color: var(--colors-primary-grey100);
}

.divider {
  display: inline;
  padding: 0px 8px;
}
