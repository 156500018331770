.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  display: inline-block;
  padding: 0 4px;
  display: flex;
  justify-content: center;
}
.disabled {
  opacity: 0.7;
}
.linkDisabled {
  cursor: not-allowed;
}

.pagination a {
  text-decoration: none;
  color: var(--colors-primary-navy100);
  padding: 8px;
  display: flex;
  align-items: center;
}

.pagination a .text {
  display: none;
}

.pagination a.pageLink {
  border: 1px solid var(--colors-primary-grey50);
  border-radius: 2px;
  min-width: 16px;
  display: inline-block;
  text-align: center;
}

.pagination a.pageLink.current {
  border-color: initial;
}

.dot {
  background: var(--colors-primary-navy100);
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 1.5px;
}

.vector {
  display: inline-block;
}

.vector.left {
  transform: rotate(-90deg);
}

.vector.right {
  transform: rotate(90deg);
}

@media (min-width: 600px) {
  .pagination a .text {
    display: inline-block;
  }
  .vector.left {
    margin-right: 8px;
  }

  .vector.right {
    margin-left: 8px;
  }
}
