.searchForm {
  border: 1px solid var(--colors-primary-grey50);
  border-radius: 2px;
  position: relative;
}

.searchForm:hover {
  background: var(--colors-primary-grey10);
  border: 1px solid var(--colors-primary-grey70);
}

.searchForm:focus-within {
  background: var(--colors-primary-white);
  outline: 2px auto var(--colors-alert-turq60);
}

.inputBox {
  border: 0;
  padding: 10px 48px 10px 16px;
  line-height: 20px;
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.inputBox:focus {
  outline: none;
}

.inputBox::placeholder {
  color: var(--colors-primary-grey80);
}

.searchLogo {
  line-height: 10px;
  position: absolute;
  right: 10px;
  top: 8px;
  pointer-events: none;
}
