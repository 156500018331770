.filterContainer {
  padding-top: 16px;
  display: flex;
  flex-flow: column;
}

.sliderWrapper {
  margin: 12px;
  order: 2;
}

.sliderWrapper > div {
  position: relative;
  overflow: visible;
}

.sliderWrapper > div > div:not([class*="range_slider"]) {
  height: 24px;
  bottom: -12px;
  width: 100%;
  position: absolute;
}

.sliderValues {
  padding-bottom: 16px;
  display: flex;
  order: 1;
  justify-content: space-between;
}

.sliderValues span {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  max-width: 45%;
}

@media (min-width: 600px) {
  .sliderValues {
    order: 3;
    padding-top: 16px;
    padding-bottom: 0;
  }
}

.rheostatWrapper {
  position: relative;
  overflow: visible;
}

.rheostatWrapper > div {
  display: block;
}

.progressBar {
  background-color: var(--colors-primary-grey40);
  position: absolute;
}

.active.progressBar {
  background-color: var(--colors-primary-blue60);
}
.progressBar.horizontal {
  height: 2px;
  top: 0px;
}

.background {
  background-color: var(--colors-primary-grey10);
  height: 15px;
  width: 100%;
  border: 1px solid var(--colors-primary-grey30);
  position: relative;
}
.background.horizontal {
  height: 2px;
  border: 0;
  background-color: var(--colors-primary-grey40);
  bottom: unset;
  left: 0;
  width: 100%;
}

.rheostatcontainer :global(.DefaultHandle_handle) {
  position: relative;
  z-index: 1;
  width: 22px;
  height: 22px;
  background-color: var(--colors-primary-white);
  border: 1px solid var(--colors-primary-grey50);
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab;
  margin-left: -11px;
}

.rheostatcontainer:global(.active) :global(.DefaultHandle_handle) {
  border: 1px solid var(--colors-primary-blue60);
}

.rheostatcontainer:global(.disabled) :global(.DefaultHandle_handle) {
  cursor: not-allowed;
  border: 1px solid var(--colors-primary-grey50);
}
