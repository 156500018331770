.buttonsWrapper {
  text-align: center;
  padding-top: 24px;
}
.buttonsWrapper button {
  width: auto;
  padding: 0 16px;
  margin-top: 16px;
  display: inline-flex;
  cursor: pointer;
}

.buttonsWrapper button span {
  font-weight: 500;
}

.buttonsWrapper button:first-child {
  margin-right: 16px;
}

@media (min-width: 600px) {
  .buttonsWrapper {
    text-align: right;
  }

  .buttonsWrapper button {
    padding: 0 32px;
  }
}
